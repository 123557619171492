import { createGlobalStyle } from 'styled-components'
import '@fontsource/red-hat-display'
import '@fontsource/red-hat-display/500.css'
import '@fontsource/red-hat-display/700.css'
import '@fontsource/red-hat-display/900-italic.css'
import '@fontsource/roboto'

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Red Hat Display', sans-serif;

    input,
    span {
        font-family: 'Red Hat Display', sans-serif;
    }

    h1 {
        font-size: 28px;
        font-weight: 700;
        margin: 60px 0 16px 0;
        line-height: 120%;
    }

    p {
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        margin: 0 0 45px 0;
    }
  }
`
