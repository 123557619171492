import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'
import { useMicrosoftClarity } from 'hooks/useMicrosoftClarity'

import Page from './components/page'
import SplashLoader from './components/splashLoader'
import { startAmplify } from 'config/aws/amplify'
import { Toggles } from './unleash/Toggles'

const Login = React.lazy(() => import('./pages/login'))
const PasswordRecovery = React.lazy(() => import('./pages/passwordRecovery'))
const OAuth = React.lazy(() => import('./pages/oauth'))
const SessionLimit = React.lazy(() => import('./pages/sessionLimit'))

const App = () => {
    const { flagsReady, flagsError } = useFlagsStatus()
    const location = useLocation()
    const clarity = useMicrosoftClarity()

    const isClarityEnabled = useFlag(Toggles.MicrosoftClarity)

    const notifyUnleashErrorIsEnabled = React.useMemo<boolean>(
        () => flagsReady && flagsError,
        [flagsReady, flagsError]
    )

    const notifyUnleashErrorInNewRelic = React.useCallback(() => {
        if (!notifyUnleashErrorIsEnabled) return
        const error = new Error(`[Unleash] Error on init (${flagsError})`)
        window.newrelic.noticeError(error)
    }, [notifyUnleashErrorIsEnabled])

    React.useEffect(() => {
        notifyUnleashErrorInNewRelic()
    }, [])

    React.useEffect(() => {
        const appId = new URLSearchParams(location.search).get('app_id')
        startAmplify(appId)
    }, [location])

    React.useEffect(() => {
        if (flagsReady && isClarityEnabled) {
            clarity.start()
        }
    }, [flagsReady])

    if (!flagsReady) return <SplashLoader />
    return (
        <Suspense fallback={<SplashLoader />}>
            <Page>
                <Switch>
                    <Route exact path='/entrar' component={Login} />
                    <Route exact path='/mobile/entrar' component={Login} />
                    <Route path='/entrar/oauth2' component={OAuth} />
                    <Route
                        path='/recuperar-senha'
                        component={PasswordRecovery}
                    />
                    <Route path='/limite-de-sessao' component={SessionLimit} />
                    <Route path='*'>
                        <Redirect to='/entrar' />
                    </Route>
                </Switch>
            </Page>
        </Suspense>
    )
}

export default App
