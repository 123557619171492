import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { sanarTheme } from '@sanardigital/design-tokens'
import sanarBackground from '../../assets/sanar-background.png'

export const Container = styled.div`
    text-align: center;
    font-size: calc(8px + 1vmin);
    background-size: cover;
    padding: 0 ${sanarTheme.spacing.stack.xxs};
    min-height: 100vh;

    @media screen and (min-width: ${breakpoints.md}px) {
        background: url(${sanarBackground}) no-repeat;
        background-size: cover;
    }

    @media only screen and (min-width: ${breakpoints.lg}px) {
        padding: ${sanarTheme.spacing.stack.sm};
    }
`
