const breakpoints = {
    xxs: 320,
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1024,
    xxl: 1200,
    xxxl: 1600
}

export default breakpoints
