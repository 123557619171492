import { FlagProvider } from '@unleash/proxy-client-react'
import React from 'react'

const config = window.env.REACT_APP_UNLEASH_CONFIG

const Unleash: React.FC = ({ children }) => (
    <FlagProvider config={config}>{children}</FlagProvider>
)

export default Unleash
