import { useCallback } from 'react'

const useMicrosoftClarity = () => {
    const start = useCallback(() => {
        const microsoftClarityScript = document.createElement('script')
        microsoftClarityScript.setAttribute('type', 'text/javascript')
        microsoftClarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${window.env.REACT_APP_MICROSOFT_CLARITY_CODE}");
      `
        document.head.appendChild(microsoftClarityScript)
    }, [])

    return { start }
}

export { useMicrosoftClarity }
