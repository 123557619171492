import Amplify from '@aws-amplify/core'

const domain: string = window.env?.REACT_APP_AWS_OAUTH_DOMAIN ?? ''
const userPoolId: string = window.env?.REACT_APP_AWS_COGNITO_USER_POOL_ID ?? ''
const isLocalhost: boolean =
    window.origin.includes('localhost') || window.origin.includes('127.0.0.1')

export const amplifyConfig = {
    Auth: {
        userPoolId,
        oauth: {
            domain,
            responseType: 'code',
            redirectSignIn: isLocalhost
                ? `${window.origin}/v1/entrar/oauth2`
                : window.env.REACT_APP_AWS_OAUTH_REDIRECT_URI
        }
    }
}

export const startAmplify = (appId: string | null) => {
    if (!appId) return
    Amplify.configure({
        ...amplifyConfig,
        Auth: {
            ...amplifyConfig.Auth,
            userPoolWebClientId: appId,
            appId
        }
    })
}
