import React from 'react'
import ReactLoading from 'react-loading'

import { Container } from './styles'
import { sanarTheme } from '@sanardigital/design-tokens'

const SplashLoader = () => (
    <Container data-testid='splash-loader'>
        <ReactLoading
            type='bubbles'
            color={sanarTheme.color.brand.primary.medium}
        />
    </Container>
)

export default SplashLoader
